import {AmogeSession, AmogeSessionInfo, useAmogeSession} from "@unclejust/amoge-client/src/utils/SessionInfo"

import {PaatsiAgent} from "../hooks/usePaatsiAgent";
import {PaatsiClient} from "../hooks/usePaatsiClient";
import * as React from "react";

export const PaatsiSessionContext = React.createContext<PaatsiSession<PaatsiSessionInfo>>(undefined);

export type CurrentClinic = {
   id: number,
   name: string
}

export type PaatsiSessionInfo = AmogeSessionInfo & {
   clinicPositions: string[]
   agent?: PaatsiAgent
   client?: PaatsiClient
   currentClinic?: CurrentClinic
}

export type PaatsiSession<T extends PaatsiSessionInfo> = AmogeSession<T> & {
   info: T,
   sysAdminLoggedIn: boolean,
   agentLoggedIn: boolean,
   clientLoggedIn: boolean,
   loggedIn: boolean,
   canDoAgent: boolean,
   clinicPositions: string[]
}

function usePaatsiSession<T extends PaatsiSessionInfo>(): PaatsiSession<T> {
   const amogeSession = useAmogeSession<T>();
   const canDoAgent = amogeSession.userCanDoAny(["PAATSI_AGENT"]);
   const paatsiSessionInfo = amogeSession.info;
   const sysAdminLoggedIn = paatsiSessionInfo.userTypes.indexOf("PaatsiAdmin") >= 0;
   const agentLoggedIn = paatsiSessionInfo.userTypes.indexOf("PaatsiAgent") >= 0;
   const clientLoggedIn = paatsiSessionInfo.userTypes.indexOf("PaatsiClient") >= 0;
   const loggedIn = !!paatsiSessionInfo.userTypes.length;
   const clinicPositions = paatsiSessionInfo.clinicPositions || [];

   return {...amogeSession, sysAdminLoggedIn, agentLoggedIn, clientLoggedIn, loggedIn, canDoAgent, clinicPositions};
}

export {usePaatsiSession}
import * as React from "react";
import {PaatsiSession, PaatsiSessionContext, PaatsiSessionInfo, usePaatsiSession} from "@unclejust/paatsi-client/src/utils/SessionInfo";
import {AmogeSessionContext} from "@unclejust/amoge-client/src/utils/SessionInfo";

export const DmcSessionContext = React.createContext<DmcSession>(undefined);

export type DmcSessionInfo = PaatsiSessionInfo & {
   drmary: {id: number}
}

export type DmcSession = PaatsiSession<DmcSessionInfo> & {
   drmary: {id: number}
}

function useDmcSession(): DmcSession {
   const amogeSession = usePaatsiSession<DmcSessionInfo>();
   return {...amogeSession, drmary: amogeSession.info.drmary}
}


function SessionProvider(props) {
   const session = useDmcSession();

   return <AmogeSessionContext.Provider value={session}>
      <PaatsiSessionContext.Provider value={session}>
         <DmcSessionContext.Provider value={session}>
            {props.children}
         </DmcSessionContext.Provider>
      </PaatsiSessionContext.Provider>
   </AmogeSessionContext.Provider>
}

export {useDmcSession, SessionProvider}
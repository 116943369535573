import {ActionLinkGroup} from "@unclejust/amoge-client/src/components/ActionLinkGroup";
import ActionLink from "@unclejust/amoge-client/src/components/ActionLink";
import Popup from "@unclejust/amoge-client/src/components/Popup";

export default function confirmInstagramVisit(evt) {
   evt.preventDefault();
   Popup.info({
      component: <InstagramLinkConfirm/>,
      css: "instagram-link-confirm-dialog"
   })
}

function InstagramLinkConfirm() {
   const no = () => {
      Popup.close();
   }

   const yes = () => {
      Popup.close();
      return Popup.waitWithFeedback<void>(done => {
         return new Promise<void>(function (resolve, reject) {
            window.location.href = "https://www.instagram.com/drmarysclinic/";
            resolve();
         }).finally(() => done())
      }, "Visiting Instagram...", "Please wait")
   }

   return <div className="instagram-link-confirm">
      <div className="popup-header info">
         Go To Instagram?
      </div>
      <div className="popup-body">
         <div><img src="/img/instagram.svg" alt="Instagram Logo"/></div>
         <div>Do you want to leave this site to visit @drmarysclinic on Instagram?</div>
      </div>
      <ActionLinkGroup className="popup-footer">
         <ActionLink action={no}>No, thanks</ActionLink>
         <ActionLink action={yes}>Yes, please!</ActionLink>
      </ActionLinkGroup>
   </div>
}
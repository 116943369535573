import {useState, useEffect} from "react";
import AmogeServer from "@unclejust/amoge-client/src/utils/AmogeServer";

export default function useMenus() {
   const menuCss = path => {
      return window.location.pathname === path + ".html" ? "selected-menu-item" : "";
   };

   const [bookingEnabled, setBookingEnabled] = useState<boolean>(false);

   useEffect(() => {
      AmogeServer("/amoge/api/client-properties").then(response => {
         setBookingEnabled(response["dmc.booking.enabled"] === "true");
      })
   }, [])

   return {menuCss, bookingEnabled}
}
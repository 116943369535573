import {useState} from "react";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faWindowClose} from "@fortawesome/free-regular-svg-icons";
import {PaatsiSessionContext} from "@unclejust/paatsi-client/src/utils/SessionInfo";
import useMenus from "./useMenus";
import confirmInstagramVisit from "./InstagramLinkConfirm";
import useDmcSettings from "../utils/DmcSettings";

export default function NavButton() {
   const {menuCss, bookingEnabled} = useMenus();
   const {registrationAllowed} = useDmcSettings();

   const [menuDropdown, setMenuDropdown] = useState(false);
   const [userMenuDropdown, setUserMenuDropdown] = useState(false);

   const toggleDropdownMenu = evt => {
      evt.preventDefault();
      setMenuDropdown(prev => !prev);
   }

   const toggleDropdownUserMenu = evt => {
      evt.preventDefault();
      setUserMenuDropdown(prev => !prev);
   }

   return <nav className="nav-button">
      <PaatsiSessionContext.Consumer>
         {session => <>
            <div className="menu-dropdown-button">
               <div>
                  <a href="#" onClick={toggleDropdownMenu}>
                     <FontAwesomeIcon icon={faBars}/>
                  </a>
               </div>
               <div>
                  {session.loggedIn && <a className="user-menu" href="#" onClick={toggleDropdownUserMenu}>
                     <FontAwesomeIcon icon={faUser}/>
                  </a>}
               </div>
            </div>
            {menuDropdown && <div className="menu-dropdown">
               <div className="menu-close">
                  <a href="#" onClick={toggleDropdownMenu}>
                     <FontAwesomeIcon icon={faWindowClose}/>
                  </a>
               </div>
               <div>
                  <a href="/index.html" className={menuCss('/home')}>Home</a>
                  <a href="" onClick={confirmInstagramVisit} className={menuCss('/gallery')}>Instagram</a>
                  <a href="treatment-info.html" className={menuCss('/treatment-info')}>Treatments</a>
                  <a href="pricing.html" className={menuCss('/pricing')}>Pricing</a>
                  <a href="about-us.html" className={menuCss('/about-us')}>About Us</a>
                  <a href="contact-us.html" className={menuCss('/contact-us')}>Contact Us</a>
                  {!session.loggedIn && <>
                     <a href="/login.html" className={menuCss('/login')}>Log In</a>
                     {registrationAllowed && <a href="/registration.html" className={menuCss('/registration')}>Register</a>}
                     {bookingEnabled && <a href="/book-now.html" className={"book-now " + menuCss('/book-now')}>Book Now</a>}
                  </>}
               </div>
            </div>}
            {userMenuDropdown && <div className="menu-dropdown">
               <div className="menu-close">
                  <a href="#" onClick={toggleDropdownUserMenu}>
                     <FontAwesomeIcon icon={faWindowClose}/>
                  </a>
               </div>
               {session.agentLoggedIn && <div>
                  <a href="/agent/dashboard.html" className={menuCss('/agent/dashboard')}>Dashboard</a>
                  <a href="/paatsi/agent/clients.html" className={menuCss('/paatsi/agent/clients')}>Clients</a>
                  <a href="/paatsi/agent/inventory.html" className={menuCss('/paatsi/agent/inventory')}>Inventory</a>
                  <a href="/paatsi/agent/profile.html" className={menuCss('/paatsi/agent/profile')}>Profile</a>
                  <a href="/paatsi/agent/price-plans.html" className={menuCss('/paatsi/agent/price-plans')}>Price Plans</a>
                  <a href="/paatsi/agent/schedule.html" className={menuCss('/paatsi/agent/schedule')}>Schedule</a>
                  <a href="/paatsi/agent/treatments.html" className={menuCss('/paatsi/agent/inventory')}>Treatments</a>
                  <a href="/amoge/api/logout">Log Out</a>
               </div>}
               {session.clientLoggedIn && <div>
                  <a href="/client/profile.html" className={menuCss('/client/profile')}>Profile</a>
                  <a href="/client/appointments.html" className={menuCss('/client/appointments')}>Appointments</a>
                  <a href="/book-now.html" className={"book-now " + menuCss('/book-now')}>Book Now</a>
                  <a href="/amoge/api/logout">Log Out</a>
               </div>}
            </div>}
         </>}
      </PaatsiSessionContext.Consumer>
   </nav>
}
import {useState, useEffect} from "react";
import AmogeServer from "@unclejust/amoge-client/src/utils/AmogeServer";

export type DmcSettings = {
   registrationAllowed: boolean
}

export default function useDmcSettings(): DmcSettings {
   const [registrationAllowed, setRegistrationAllowed] = useState(false);

   useEffect(() => {
      AmogeServer("/amoge/api/client-properties", {}, false).then(clientProperties => {
         const registrationAllowedProperty = clientProperties["dmc.login.enabled"];

         const trueRegex = /^true$/i;

         registrationAllowedProperty && setRegistrationAllowed(trueRegex.test(registrationAllowedProperty));
      })
   }, [])

   return {registrationAllowed}
}